import React, {useEffect, useState} from "react";
import {Dropdown, Space} from "antd";
import { DownOutlined } from '@ant-design/icons';
import {fillObjectFromArr, indicatorValue, transformObject} from "../utilsForFinanceTab";
import Arrow from "../../../../../../images/arrow-down-sign-to-navigate.png";
import {FinanceSearchInput} from "../finance-search-input/finance-search-input";
import {FinanceBarChart} from "../finance-bar-chart/finance-bar-chart";
import {FinanceTable} from "../finance-table/finance-table";
import {getFinanceDataForScreener} from "../../../../../../utils/api";
import styles from "./finance-block.module.css";

export const FinanceBlock = ({keyBlock, tableRowTitles, tableRowTitlesSecond, selectedRowsForMapping, setSelectedCompany, selectedCompany, value, information, period, setPeriod, setData, values, setValues}) => {
    const [isShowBlock, setIsShowBlock] = useState(true)

    const getFinanceDataForBlock = (ticker, keyBlock) => {
        getFinanceDataForScreener(ticker, period).then(async (res) => {
            const setFunc = setSelectedCompany(keyBlock)
            setFunc(res)
        })
            .catch((e) => {
                console.log(e)
            });
    }

    const getFinanceDataValues = (values) => {
        setValues(values)
    }

    const getFinanceDataForPeriod = (period) => {
        setPeriod(period)
        // setIsLoading(true)
        getFinanceDataForScreener(information.main_info.ticker, period).then(async (res) => {
            let dataBlock = {}

            if(period === 'Q') {
                if (keyBlock === 'incomeStatement') dataBlock.incomeStatement = res.data.incomeStatement
                if (keyBlock === 'balanceSheet') dataBlock.balanceSheet = res.data.balanceSheet
                if (keyBlock === 'cashFlow') dataBlock.cashFlow = res.data.cashFlow
                if (keyBlock === 'ratios') dataBlock.ratios = res.data.ratios
            } else {
                let ttmValues = {}
                let averageFiveYearsValues = {}
                let averageTenYearsValues = {}

                if (res.ttm) ttmValues = transformObject(res.ttm, 'ttm')
                if (res.averages_5y) averageFiveYearsValues = transformObject(res.averages_5y, 'average5')
                if (res.averages_10y) averageTenYearsValues = transformObject(res.averages_10y, 'average10')

                if (res.data.incomeStatement) dataBlock.incomeStatement = fillObjectFromArr(res.data.incomeStatement, ttmValues.incomeStatement, averageFiveYearsValues.incomeStatement, averageTenYearsValues.incomeStatement)
                if (res.data.balanceSheet) dataBlock.balanceSheet = fillObjectFromArr(res.data.balanceSheet, ttmValues.balanceSheet, averageFiveYearsValues.balanceSheet, averageTenYearsValues.balanceSheet)
                if (res.data.cashFlow) dataBlock.cashFlow = fillObjectFromArr(res.data.cashFlow, ttmValues.cashFlow, averageFiveYearsValues.cashFlow, averageTenYearsValues.cashFlow)
                if (res.data.ratios) dataBlock.ratios = fillObjectFromArr(res.data.ratios, ttmValues.ratios, averageFiveYearsValues.ratios, averageTenYearsValues.ratios)
            }
            setData((prevState) => {
                return {...prevState, ...dataBlock}
            })
            // setIsLoading(false)
        })
            .catch((e) => {
                console.log(e)
                // setError('Финансовые данные по данной компании отсутствуют')
                // setIsLoading(false)
            });
    }

    const periodItems = [
        {
            label: <span onClick={()=>getFinanceDataForPeriod('Y')}>По годам</span>,
            key: '0',
        },
        {
            label: <span onClick={()=>getFinanceDataForPeriod('Q')}>По кварталам</span> ,
            key: '1',
        }
    ];

    const valuesItems = [
        {
            label: <span onClick={()=>getFinanceDataValues('rub')}>Значения, RUB</span>,
            key: '2',
        },
        {
            label: <span onClick={()=>getFinanceDataValues('changeYoy')}>Изменения г/г, %</span> ,
            key: '3',
        },
        {
            label: <span onClick={()=>getFinanceDataValues('change')}>Изменения к/к, %</span> ,
            key: '4',
        }
    ];

    const handleLegendClick = (record) => {
        const setSelectedFunc = selectedRowsForMapping(keyBlock, true)
        setSelectedFunc((prevSelectedRows) => {
                return prevSelectedRows.filter(key => key !== record);
            })
    };

    return (
        <div className={styles.chartBlock}>
            <div className={styles.titleContainer}>
                <h3 className={styles.blockTitle}>{indicatorValue(keyBlock)}</h3>
                <button className={styles.showHideBtn} onClick={()=>setIsShowBlock(prevState => !prevState)}>
                    <img src={Arrow} alt="arrow" className={isShowBlock ? styles.arrowUp : styles.arrowDown} width={15}/>
                </button>
            </div>
            {isShowBlock && <>
            <FinanceSearchInput keyBlock={keyBlock} getFinanceDataForBlock={getFinanceDataForBlock}
                                selectedCompany={selectedCompany(keyBlock, true)}
                                setSelectedCompany={setSelectedCompany(keyBlock)}/>
            <FinanceBarChart tableRowTitles={tableRowTitles}
                             selectedRows={selectedRowsForMapping(keyBlock)}
                             dataBlock={value} period={period}
                             ticker={information.main_info?.ticker}
                             dataForCompare={selectedCompany(keyBlock)}
                             tableRowTitlesSecond={tableRowTitlesSecond}
                             selectedCompany={selectedCompany(keyBlock, true)}
                             handleLegendClick={handleLegendClick}
                             values={values}
            />
                <div className={styles.dropdownContainer}>
                <Dropdown menu={{ items: periodItems }} trigger={['click']} className={styles.dropdown}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {period === 'Y' ? 'По годам' : 'По кварталам'}
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                <Dropdown menu={{ items: valuesItems }} trigger={['click']} className={styles.dropdown}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {values === 'rub' ? 'Значения, RUB' : values === 'changeYoy' ? 'Изменения г/г, %' : 'Изменения к/к, %'}
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
                </div>

            <FinanceTable selectedRows={selectedRowsForMapping(keyBlock)}
                          setSelectedRows={selectedRowsForMapping(keyBlock, true)}
                          dataBlock={value} dataTest={selectedCompany(keyBlock)}
                          keyBlock={keyBlock} period={period} values={values}
            />
            </>}

        </div>
    );
};