import React, { useState } from 'react';

import Artem from '../../images/Artem.png';
import Star from '../../images/tuiIconStarLarge.svg';
import OOO from '../../images/ООО.svg';
import Company from '../../images/tuiIconCompanyLarge.svg';
import MarksLeft from '../../images/marks-left.svg';
import MarksRight from '../../images/marks-right.svg';
import Aleksey from '../../images/Aleksey.png';
import Artem1 from '../../images/Artem1.png';
import Anatoliy from '../../images/Anatoliy.png';
import Irina from '../../images/Irina.png';
import Dmitriy from '../../images/Dmitriy.png';
import Close from '../../images/close.svg';
import Open from '../../images/open.svg';

import styles from './our-team.module.css';
const OurTeam = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
          window.ym(96742836, 'reachGoal', 'main-start');
          console.log('Copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
  };

  const questionArr = [
    {
      id: '1',
      title: 'Чем мы занимаемся?',
      descr: [
        'Помогаем инвесторам увеличить доходность за счет качественной аналитики, инвест идей и обучения.',
      ],
      open: true,
    },
    {
      id: '2',
      title: 'В чем основная задача Invest Era?',
      descr: ['Сделать так, чтобы портфели наших клиентов обгоняли индекс.'],
      open: false,
    },
    {
      id: '3',
      title: ' В чем ваша специализация?',
      descr: [
        'Фундаментальный анализ на фондовом рынке. В подготовке инвест-идей мы, в первую очередь, ориентируемся на финансовые показатели и перспективы компаний, а также конъюнктуру рынка. Нам помогает богатый опыт и понимание психологии толпы, собственная модель по прогнозированию прибылей и дивидендов компаний. Понимаем из-за каких факторов растут или снижаются фин. показатели, какие статьи разовые и в каких компаниях можно использовать РСБУ как подсказку, а в каких нет.',
      ],
      open: false,
    },
    {
      id: '4',
      title: 'Как вы помогаете увеличивать доходность?',
      descr: [
        'Рассказываем какие акции покупать, а какие продавать и почему.',
        'Учим анализировать и выбирать компании в портфель.',
        'Ведем портфель, на основе которого можно собрать свой собственный.',
        'Быстро даем рекомендации при выходе новостей, как по отдельным компаниям, так и по макроэкономике.',
        'Индивидуально отвечаем на вопросы подписчиков и помогаем в принятии решений.',
      ],
      open: false,
    },
    {
      id: '5',
      title: 'Почему следует выбрать вас?',
      descr: [
        'Опытные инвесторы ценят нас за глубину и качество наших разборов. Начинающие инвесторы ценят нас за индивидуальный подход. Даже если у вас совсем нет опыта, мы ответим на каждый ваш вопрос в чате и поможем разобраться с трудностями на фондовом рынке.',
      ],

      open: false,
    },
    {
      id: '6',
      title: 'Хочу приобрести подписку, но не уверен, что понравится…',
      descr: [
        <span>У нас есть пробная подписка на 10 дней по промокоду START. Этого периода недостаточно, чтобы получить результат, но можно оценить уровень работы нашей команды.<br/><a href="/profile" className={styles.links} onClick={() => copyToClipboard('START')}>Скопировать промокод START и перейти к оформлению</a></span>
      ],
      open: false,
    },
    {
      id: '7',
      title: 'В чем разница между Unity и Ideas? Не могу понять, какой из сервисов мне подходит?',
      descr: [
        <span>Unity – для тех, кто хочет самостоятельно инвестировать и разбираться в фондовом рынке.</span>,
        <span>Ideas – для тех, кто хочет следовать за сделками и получать результат, тратя минимум времени. Есть как спекулятивные портфели, так и долгосрочные.</span>,
        <a href="/profile" className={styles.links}>Подробнее о тарифах</a>
      ],
      open: false,
    },
    {
      id: '8',
      title: 'Хочу подписаться на Ideas. Слышал, что вы делаете разбор портфеля на старте, так ли это?',
      descr: ['Да, мы действительно помогаем инвесторам с портфелями на старте. В рамках подписки на Ideas поможем привести Ваш портфель к нашему. Далее Вы будете следовать сделкам.'],
      open: false,
    },
    {
      id: '9',
      title: 'Даёт ли покупка подписки Ideas опции, которые включены в подписку Unity? Например, обучающие материалы, разборы компаний и т.д.',
      descr: ['К сожалению, нет. Это два разных сервиса.'],
      open: false,
    },
    {
      id: '10',
      title: 'Есть ли для Ideas свой чат?',
      descr: [
        <span>Закрытый чат отсутствует. Вы можете отправлять свои вопросы по сделкам на наш аккаунт: <a href="https://t.me/ie_contact" className={styles.links} target={'_blank'} rel='noopener noreferrer'>@ie_contact</a></span>
      ],
      open: false,
    },
    {
      id: '11',
      title: 'Что мне будет доступно после оплаты подписки?',
      descr: ['После оплаты подписки для Вас становятся доступными закрытые материалы по подписке на сайте, а также в Telegram. Вам на почту, на которую вы оформите подписку, придет приветственное письмо со ссылкой на бота, который выдаст Вам доступ к каналам в Telegram. В случае отсутствия письма на почте – не забудьте проверить папку Спам. Материалы на сайте доступны после авторизации.'],
      open: false,
    },
    {
      id: '12',
      title: 'Хочу оплатить подписку из-за рубежа. На сайте вижу только оплату картой РФ. Как мне можно купить подписку?',
      descr: [
        <span>Мы подготовим для Вас счет для оплаты иностранной картой. Для этого напишите нам: <a href="https://t.me/ie_contact" className={styles.links} target={'_blank'} rel='noopener noreferrer'>@ie_contact</a></span>
      ],
      open: false,
    },
    {
      id: '13',
      title: 'Вы пишите о сделках в онлайн-режиме?',
      descr: ['Да, это основная функция в Ideas. Вы получаете структуру портфеля, сделки с точкой входа, размером позиции и обоснованием, как при открытии, так и при закрытии позиции.'],
      open: false,
    },
    {
      id: '14',
      title: 'У меня уже есть готовый портфель. Как мне поможет Invest Era?',
      descr: [
          'На основании наших разборов и аналитики Вы можете продать слабые акции и купить сильные, задать вопросы по своему портфелю в чате. Также Вы можете взять за основу наши модельные портфели.',
      ],
      open: false,
    },
    {
      id: '15',
      title: 'На сколько портфель может обгонять инфляцию?',
      descr: ['Среднегодовой результат нашего портфеля 31.4%, среднегодовая инфляция в РФ за 10 лет – 8.1%.'],
      open: false,
    },
    {
      id: '16',
      title: 'Работаете ли вы с деривативами (фьючерсы, опционы)?',
      descr: ['Это не основные инструменты в нашей работе, они используются периодически.'],
      open: false,
    },
    {
      id: '17',
      title: 'Работаете ли вы с криптовалютой?',
      descr: [
        'Нет. Наша специализация это фондовый рынок: акции, облигации, ETF. Мы специализируемся на одном конкретном направлении и не распыляем силы.',
      ],
      open: false,
    },
    {
      id: '18',
      title: 'Нет ответа на нужный вопрос?',
      descr: [
        <span>Будем рады Вам ответить: <a href="https://t.me/ie_contact" className={styles.links} target={'_blank'} rel='noopener noreferrer'>@ie_contact</a></span>
      ],

      open: true,
    },
  ];
  const [open, setOpen] = useState(questionArr);

  const onToggleOpen = (id) => {
    setOpen(
      open.map((item) => {
        if (item.id === id) {
          return { ...item, open: !item.open };
        }
        return item;
      }),
    );
  };

  return (
    <div className={styles.ourTeam}>
      <h2 className={styles.title}>Наша команда</h2>
      <div className={styles.wrapperProfile}>
        <div className={styles.profile}>
          <div className={styles.description}>
            <div className={styles.image}>
              <img src={Artem} alt="photo" />
            </div>
            <div className={styles.name}>
              Артём Николаев
              <span className={styles.position}>CEO</span>
            </div>
          </div>
          <ul className={styles.experience}>
            <li className={styles.list}>
              {' '}
              <img src={Star} alt="star" />
              Основатель Invest Era
            </li>
            <li className={styles.list}>
              {' '}
              <img src={OOO} alt="OOO" />
              Руководитель аналитического отдела
            </li>
            <li className={styles.list}>
              {' '}
              <img src={Company} alt="company" />
              Консультирует более 30 клиентов <br /> с портфелями 100+ млн рублей
            </li>
          </ul>
        </div>
        <div className={styles.comment}>
          <div className={styles.text}>
            <img className={styles.marksLeft} src={MarksLeft} alt="marks" />
            Наша Миссия – помогать инвесторам достигать своих финансовых целей и улучшать свою жизнь
            <img className={styles.marksRight} src={MarksRight} alt="marks" />
          </div>
        </div>
      </div>
      <div className={styles.wrapperTeam}>
        <div className={styles.teamItem}>
          <div className={styles.teamInfo}>
            <div className={styles.itemName}>Алексей Наумов</div>
            <div className={styles.itemPosition}>Инвестиционный аналитик</div>
            <div className={styles.itemExperience}>
              Более 10 лет на руководящих должностях в банках и фондах. Управлял более чем 10 b$.
              Кандидат экономических наук, CFA
            </div>
          </div>
          <div className={styles.teamImg}>
            <img src={Aleksey} alt="photo" />
          </div>
        </div>
        <div className={styles.teamItem}>
          <div className={styles.teamInfo}>
            <div className={styles.itemName}>Артём Приказчиков</div>
            <div className={styles.itemPosition}>Руководитель IT отдела</div>
            <div className={styles.itemExperience}>Отвечает за IT и продуктовое направление</div>
          </div>
          <div className={styles.teamImg}>
            <img src={Artem1} alt="photo" />
          </div>
        </div>
        <div className={styles.teamItem}>
          <div className={styles.teamInfo}>
            <div className={styles.itemName}>Анатолий Мухин</div>
            <div className={styles.itemPosition}>Инвестиционный аналитик</div>
            <div className={styles.itemExperience}>
              Работал в ВТБ Капитал на ведущих позициях в роли аналитика и трейдера. Выпускник РЭШ
            </div>
          </div>
          <div className={styles.teamImg}>
            <img src={Anatoliy} alt="photo" />
          </div>
        </div>
        <div className={styles.teamItem}>
          <div className={styles.teamInfo}>
            <div className={styles.itemName}>Ирина Трусова</div>
            <div className={styles.itemPosition}>Руководитель клиентского сервиса</div>
            <div className={styles.itemExperience}>
              Отвечает за техподдержку и сопровождение клиентов
            </div>
          </div>
          <div className={styles.teamImg}>
            <img src={Irina} alt="photo" />
          </div>
        </div>
        <div className={styles.teamItem}>
          <div className={styles.teamInfo}>
            <div className={styles.itemName}>Дмитрий Толмачев</div>
            <div className={styles.itemPosition}>Инвестиционный аналитик</div>
            <div className={styles.itemExperience}>
              Работал в ЦБ РФ, Почта Банк, Тинькофф, Сбербанк и Дом РФ финансовым аналитиком в
              отделах финансового моделирования. Выпускник финансового университета
            </div>
          </div>
          <div className={styles.teamImg}>
            <img src={Dmitriy} alt="photo" />
          </div>
        </div>
      </div>

      <h2 className={styles.question}>Часто задаваемые вопросы</h2>
      <div className={styles.wrapperQuestions}>
        {open &&
          open.map((item) => (
            <div key={item.id} className={styles.questionItem}>
              <div className={styles.questionItemDescription}>
                <h3 className={styles.questionSubtitle}>{item.title}</h3>
                {item.open &&
                  item.descr.map((line, index) => (
                    <p key={index} className={styles.paragraph}>
                      {line}
                    </p>
                  ))}
              </div>
              <div className={styles.close} onClick={() => onToggleOpen(item.id)}>
                {item.open ? <img src={Close} alt="close" /> : <img src={Open} alt="open" />}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OurTeam;
