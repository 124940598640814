import React, {useEffect, useState} from "react";
import {FinanceBarChart} from "../screener-tab-finance/finance-bar-chart/finance-bar-chart";
import {FinanceTable} from "../screener-tab-finance/finance-table/finance-table";
import {getFinanceDataForScreener} from "../../../../../utils/api";
import {generateTableRowPredictTitles,} from "../screener-tab-finance/utilsForFinanceTab";
import Preloader from "../../../../preloader/preloader";
import styles from "./screener-tab-predictions.module.css";

export const ScreenerTabPredictions = ({information}) => {
    const [data, setData] = useState({})
    const [selectedPredictRows, setSelectedPredictRows] = useState(['Revenue', 'EBITDA']);
    const [values, setValues] = useState('rub')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const tableRowTitles = generateTableRowPredictTitles(data)

    const handleLegendClick = (record) => {
        setSelectedPredictRows((prevSelectedRows) => {
            return prevSelectedRows.filter(key => key !== record);
        })
    };
/*
    const getFinanceDataValues = (values) => {
        setValues(values)
    }*/

/*    const valuesItems = [
        {
            label: <span onClick={()=>getFinanceDataValues('rub')}>Значения, RUB</span>,
            key: '2',
        },
        {
            label: <span onClick={()=>getFinanceDataValues('changeYoy')}>Изменения г/г, %</span> ,
            key: '3',
        },
        {
            label: <span onClick={()=>getFinanceDataValues('change')}>Изменения к/к, %</span> ,
            key: '4',
        }
    ];*/

    useEffect(() => {
        setIsLoading(true)
        getFinanceDataForScreener(information.main_info.ticker, 'Y').then(async (res) => {
            let reorderedObj
            if (res.data.predictions_2024 || res.data.predictions_2025) {
                reorderedObj = {
                    predictions_2024: res.data.predictions_2024,
                    predictions_2025: res.data.predictions_2025
                };
                setData(reorderedObj)
            } else {
                setError('Прогнозы по данной компании отсутствуют')
            }

            setIsLoading(false)
        })
            .catch((e) => {
                console.log(e)
                setError('Прогнозы по данной компании отсутствуют')
                setIsLoading(false)
            });

    }, [information]);

    return (
        <div className={styles.chartBlock}>
            <div className={styles.titleContainer}>
                <h3 className={styles.blockTitle}>Прогнозы</h3>
            </div>
            {!isLoading && Object.keys(data).length !== 0?
                <>{!error ? <>
                    <FinanceBarChart tableRowTitles={tableRowTitles}
                                     selectedRows={selectedPredictRows}
                                     dataBlock={data} keyBlock={'predictions'}
                                     ticker={information.main_info?.ticker}
                                     handleLegendClick={handleLegendClick}
                                     values={'rub'}
                    />
{/*                        <Dropdown menu={{ items: valuesItems }} trigger={['click']} className={styles.dropdown}>
                            <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                    {values === 'rub' ? 'Значения, RUB' : values === 'changeYoy' ? 'Изменения г/г, %' : 'Изменения к/к, %'}
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>*/}
                    <FinanceTable selectedRows={selectedPredictRows}
                                  setSelectedRows={setSelectedPredictRows}
                                  dataBlock={data} keyBlock={'predictions'}
                    />
                </>
                :
                <div>{error}</div>}</>
                :
                <Preloader />
            }
        </div>
    );
};
